import {FormikProps, FormikValues} from 'formik'
import {useCallback} from 'react'
import {toast} from 'react-toastify'
import CustomToast, {CustomToastStyling} from '../components/Widgets/ToastCollection/CustomToast'
import {getErrorMessage, getErrors} from '../utils/api-utils'
import {useOnline} from './online.hook'

export interface IErrorQuery {
  errorTemp: (e: unknown) => void
  errorComplete: (e: unknown, formik?: FormikProps<unknown>) => void
}

export function useErrorQuery() {
  const {isOnline, errorOffline} = useOnline()

  const isAxiosError = (error: unknown): error is {response: {status: number}} =>
    typeof error === 'object' && error !== null && 'response' in error

  const errorOnlineToast = useCallback((e: unknown) => {
    if (isAxiosError(e) && e.response.status >= 400 && e.response.status < 500) {
      if (e.response.status === 404) {
        toast(
          <CustomToast
            message='Client Error: Please check your request and try again.'
            variant='DANGER'
          />,
          CustomToastStyling
        )
      } else {
        toast(
          <CustomToast message={getErrorMessage(e, true)} variant='DANGER' />,
          CustomToastStyling
        )
      }
    }

    if (isAxiosError(e) && e.response.status >= 500) {
      toast(
        <CustomToast message={'Something went wrong, please try again later.'} variant='DANGER' />,
        CustomToastStyling
      )
    }
  }, [])

  const errorTemp = useCallback(
    (e: unknown) => {
      if (isOnline) {
        errorOnlineToast(e)
      } else {
        toast(<CustomToast message={errorOffline} variant='DANGER' />, CustomToastStyling)
      }
    },
    [errorOffline, isOnline, errorOnlineToast]
  )

  const errorComplete = useCallback(
    <T extends FormikValues>(e: unknown, formik?: FormikProps<T>) => {
      if (isOnline) {
        errorOnlineToast(e)

        const detailError = getErrors(e).detailed

        Object.entries(detailError).forEach(([key, value]) => {
          const removedValue = [
            'created_at',
            'updated_at',
            'deleted_at',
            'created_by',
            'updated_by',
            'deleted_by',
            'created_by_id',
            'updated_by_id',
            'deleted_by_id',
          ]
          if (removedValue.includes(key)) return

          if (['date'].includes(key)) {
            formik?.setFieldError('start_date', value)
            formik?.setFieldError('end_date', value)
          }

          formik?.setFieldError(`${key}`, value)
        })
      } else toast(<CustomToast message={errorOffline} variant='DANGER' />, CustomToastStyling)
    },
    [errorOffline, isOnline, errorOnlineToast]
  )

  return {errorTemp, errorComplete}
}
