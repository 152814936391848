import {EFormJobType, EJobProgress} from '../models'

export const constantJobTypeList: Record<EFormJobType, string> = {
  [EFormJobType.TEXT]: 'Text Field',
  [EFormJobType.MULTIPLE_CHOICE]: 'Multiple Choice',
  [EFormJobType.DROPDOWN]: 'Dropdown',
  [EFormJobType.IMAGE]: 'Image',
  [EFormJobType.DATE]: 'Date',
  [EFormJobType.TIME]: 'Time',
}

export const constantJobProgress: Record<EJobProgress, string> = {
  [EJobProgress.CLOSED]: 'Disetujui',
  [EJobProgress.COMPLETED]: 'Selesai',
  [EJobProgress.DRAFT]: 'Draf',
  [EJobProgress.IN_PROGRESS]: 'Berlangsung',
  [EJobProgress.OPEN]: 'Terbuka',
  [EJobProgress.REJECTED]: 'Ditolak',
}

export const formJobTypeOpt = Object.entries(constantJobTypeList).map(([key, label]) => ({
  value: key as EFormJobType,
  label,
}))
