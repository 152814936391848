export type BaseResponse<T> = {
  response_schema?: {
    response_code?: string
    response_message?: string
  }
  response_output?: T
}

export type PaginateModel = {
  page?: number
  total?: number
  size?: number
  rows_per_page?: number
}

export type Pagination<T> = {
  list?: {
    content?: T
    pagination?: PaginateModel
  }
}

export type Response<T> = {
  detail?: T
}

export type Constraint = {
  code?: string
  message?: string
}

export enum Order {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type TTableDefault<Q> = {
  page: number
  size: number
  total: number
} & Q
