import {Order} from 'src/app/models'

export interface IGlobal {
  created_at?: string
  updated_at?: string
  deleted_at?: string
  id?: string
  slug?: string
  code?: string
  order?: number
  value?: string
  value_ext?: string
}

export interface IGlobalQuery {
  search?: string
  page?: number
  size?: number
  sort?: string
  order?: Order
}

export enum EGlobalSlug {
  GENDER = 'gender',
  REGEX = 'regex',
  // TASK
  TASK_STATUS = 'task_status',
  TASK_PRIORITY = 'task_priority',
  TASK_PROGRESS = 'task_progress',
  TASK_REMINDER = 'task_reminder',
  TASK_RECURRING_FREQUENCY = 'task_recurring_frequency',
  // USER
  USER_LEVEL = 'user_level',
  USER_STATUS = 'user_status',
  // CLIENT
  CLIENT_STATUS = 'client_status',
}

export type TGlobalSlug = keyof typeof EGlobalSlug
