import {ScreenProp} from 'src/app/models'
import {LogScreen, TLogKeys} from '../page/Log/Screen'

type TKeys = 'LOG_ACTIVITY' | TLogKeys

const LogActivityScreens: Record<TKeys, ScreenProp> = {
  LOG_ACTIVITY: {
    PATH: '/log-activity',
    TITLE: 'Aktivitas Log',
    TITLE_ALT: 'Aktivitas Log',
  },

  ...LogScreen,
}

export default LogActivityScreens
