import {FC} from 'react'

const AsideGradient: FC = () => {
  return (
    <div className='absolute right-0 w-full h-full overflow-hidden'>
      <div className='sticky bg-gradient-to-br  from-[#F2F4F7]/5 to-[#F1F9FE]/5 z-[3] w-[260px] h-[260px] -rotate-[45deg] -translate-x-[130px] -translate-y-[130px]'></div>
      <div className='sticky bg-gradient-to-br from-[#E5E5E5]/5 to-[#EFF4FF]/5 z-[2] w-[600px] h-[400px] -rotate-[45deg] -translate-x-[300px] -translate-y-[350px]'></div>
      <div className='sticky bg-gradient-to-br from-[#E5E5E5]/15 to-[#EFF4FF]/15 z-[1] w-[675px] h-[595px] -rotate-[45deg] -translate-x-[350px] -translate-y-[800px]'></div>
    </div>
  )
}

export default AsideGradient
