import axios from 'axios'
import {
  BaseResponse,
  IAgen,
  IAgenBulkRequest,
  IAgenDetail,
  IAgenQuery,
  IAgenRequest,
  Pagination,
  Response,
} from 'src/app/models'

export const AGEN = '/api/v1/admins/agents'

//react-query key
export const LIST_AGEN_KEY = 'list-agen'
export const DETAIL_AGEN_KEY = 'detail-agen'

const multiFormDataHeaders = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
}

export const getListAgen = (params?: IAgenQuery) => {
  return axios.get<BaseResponse<Pagination<IAgen[]>>>(AGEN, {
    params,
  })
}

export const getDetailAgen = (id?: string) => {
  return axios.get<BaseResponse<Response<IAgenDetail>>>(`${AGEN}/${id}`)
}

export const saveAgen = (data: IAgenRequest, id?: string) => {
  if (id) return axios.put(`${AGEN}/${id}`, data)
  return axios.post(AGEN, data)
}

export const deleteAgen = (id?: string) => {
  return axios.delete(`${AGEN}/${id}`)
}

export const updateStatusAgen = (agen_id?: string, agen_status?: 'ACTIVE' | 'INACTIVE') => {
  return axios.patch(`${AGEN}/${agen_id}/status/${agen_status}`)
}

export const uploadAgen = (data?: any) => {
  return axios.post(`${AGEN}/upload`, data, {...multiFormDataHeaders})
}

export const uploadBulkAgen = (data?: IAgenBulkRequest[], session_id?: string) => {
  return axios.post(`${AGEN}/bulk/${session_id}`, data)
}
