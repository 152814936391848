import {Redirect, Route, Switch} from 'react-router-dom'
import AuthRoutes from '../modules/auth/AuthRoutes'
import OnboardingAgent from '../modules/onboarding/page/OnboardingAgent'
import OnboardingRoutes from '../modules/onboarding/routes'
import OnboardingScreens from '../modules/onboarding/routes/Screen'

export function PublicRoutes() {
  return (
    <Switch>
      <Route path={OnboardingScreens.ONBOARDING.PATH} component={OnboardingRoutes} />
      <Route path={OnboardingScreens.ONBOARDING_AGENT.PATH} component={OnboardingAgent} />
      <Route path='/auth' component={AuthRoutes} />
      <Redirect to='/auth' />
    </Switch>
  )
}
