import {ScreenProp} from 'src/app/models'
import {JobTugasScreen} from '../page/Job/Screen'

type TJobTugasKeys = keyof typeof JobTugasScreen

type TKeys = 'JOB' | TJobTugasKeys

const JobScreens: Record<TKeys, ScreenProp> = {
  JOB: {
    PATH: '/job',
    TITLE: 'Job & Tugas',
    TITLE_ALT: 'Job & Tugas',
  },

  ...JobTugasScreen,
}

export default JobScreens
