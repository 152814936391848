import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export interface IFilterState {
  filter?: any
  location?: string
}

namespace FilterRedux {
  export const actionTypes = {
    SetFilter: '[SetFilter] Action',
    SetRemoveFilter: '[SetRemoveFilter] Action',
  }

  export const initialFilterState: IFilterState = {
    filter: undefined,
    location: undefined,
  }

  export const reducer = () =>
    persistReducer<IFilterState, ActionWithPayload<IFilterState>>(
      {
        storage,
        key: 'LIST-FILTER',
        whitelist: ['filter', 'location'],
      },
      (state: IFilterState = initialFilterState, action = {type: ''}) => {
        switch (action.type) {
          case actionTypes.SetFilter: {
            const filter = action.payload?.filter
            const location = action.payload?.location

            return {...state, filter, location}
          }

          case actionTypes.SetRemoveFilter: {
            return initialFilterState
          }

          default:
            return state
        }
      }
    )

  export const actions = {
    setFilter: ({filter, location}: any) => ({
      type: actionTypes.SetFilter,
      payload: {
        filter,
        location,
      },
    }),
    setRemoveFilter: () => ({type: actionTypes.SetRemoveFilter}),
  }
}

export default FilterRedux
