import axios from 'axios'
import {BaseResponse, Response} from 'src/app/models'
import {
  IOnboardingActivation,
  IOnboardingAgent,
  IOnboardingAgentRequest,
} from 'src/app/models/Onboarding/Onboarding.models'

export const ONBOARDING = `/api/v1/admins/onboarding`
export const VERIFY_ONBOARDING_ACTIVATION = `/api/v1/admins/auth/activation`
export const ONBOARDING_AGENT = `/api/v1/admins/agents/pin`
export const VERIFY_ONBOARDING_AGENT = `/api/v1/admins/auth/activation/agent`

export const saveOnboarding = (data?: any) => {
  return axios.post(ONBOARDING, data)
}

export const verifyOnboardingActivation = (params: {t?: string}) => {
  return axios.get<BaseResponse<Response<IOnboardingActivation>>>(
    `${VERIFY_ONBOARDING_ACTIVATION}`,
    {params}
  )
}

export const saveOnboardingAgent = (data?: IOnboardingAgentRequest, activation_code?: string) => {
  return axios.put(`${ONBOARDING_AGENT}/${activation_code}`, data)
}

export const verifyOnboardingAgent = (params: {t?: string}) => {
  return axios.get<BaseResponse<Response<IOnboardingAgent>>>(`${VERIFY_ONBOARDING_AGENT}`, {params})
}
