import {useMutation} from '@tanstack/react-query'
import {FC, useEffect, useState} from 'react'
import PinInput from 'react-pin-input'
import {useDispatch} from 'react-redux'
import {useHistory, useLocation} from 'react-router-dom'
import {GButton} from 'src/app/components/Libs'
import {useErrorQuery} from 'src/app/hooks/error-query.hook'
import {useWebTitle} from 'src/app/hooks/title.hook'
import {
  IOnboardingAgent,
  IOnboardingAgentRequest,
} from 'src/app/models/Onboarding/Onboarding.models'
import {saveOnboardingAgent, verifyOnboardingAgent} from 'src/app/services'
import AuthRedux from 'src/app/store/Auth/AuthRedux'
import AuthScreens from '../../auth/AuthScreens'
import ExpiredSection from '../components/ExpiredSection/ExpiredSection'
import OnboardingScreens from '../routes/Screen'

const OnboardingAgent: FC = () => {
  useWebTitle('Aktivasi Agen')
  const history = useHistory()
  let query = new URLSearchParams(useLocation().search)
  const token = query.get('t') as string
  const dispatch = useDispatch()
  const {errorTemp} = useErrorQuery()

  const [pin, setPin] = useState('')
  const [response, setResponse] = useState<IOnboardingAgent>({})
  const [activeSession, setActiveSession] = useState<boolean>(true)

  useEffect(() => {
    dispatch(AuthRedux.actions.logout())
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (token) {
      verifyOnboardingAgent({t: token})
        .then((res) => {
          const data = res?.data?.response_output?.detail

          setResponse(data as IOnboardingAgent)

          if (data?.success) {
            setActiveSession(true)
          } else setActiveSession(false)
        })
        .catch(() => {
          setActiveSession(false)
        })
    }
  }, [token, dispatch])

  useEffect(() => {
    if (!token) history.replace(AuthScreens.LOGIN_EMAIL.PATH)
  }, [history, token])

  const submitFn = useMutation({
    mutationFn: () => {
      const payload: IOnboardingAgentRequest = {
        company_code: response.company_code,
        mobile_number: response.mobile_number,
        pin: pin,
      }

      return saveOnboardingAgent(payload, token)
    },
    onSuccess: () => {
      history.replace(OnboardingScreens.ONBOARDING_SUCCESS.PATH)
    },
    onError: (e) => errorTemp(e),
  })
  if (!token) return null

  if (!activeSession) return <ExpiredSection />

  return (
    <div className='flex items-center justify-center w-full h-screen bg-neutral-25'>
      <div className='text-center px-6 py-12 w-[420px] h-[420px] bg-white shadow-lg border border-neutral-50 rounded-lg flex flex-col justify-between items-center'>
        <div>
          <div className='font-semibold text-neutral-900 text-fs-6'>Masukkan PIN</div>
          <div className='mt-6 text-neutral-700'>
            Proses onboarding nomor{' '}
            <span className='font-semibold text-neutral-900'>{response.mobile_number}</span> hampir
            selesai
          </div>
        </div>

        <div>
          <PinInput
            length={6}
            onChange={(value) => setPin(value)}
            type='numeric'
            inputMode='number'
            style={{padding: '20px'}}
            inputStyle={{borderColor: 'gray'}}
            inputFocusStyle={{borderColor: 'black'}}
            focus
            // onComplete={(value) => {}}
            autoSelect={true}
          />
        </div>

        <div className='w-full'>
          <GButton
            className='w-full'
            size='large'
            disabled={pin.length < 6 || submitFn.isLoading}
            onClick={() => submitFn.mutate()}
            loading={submitFn.isLoading}
          >
            Verifikasi
          </GButton>
        </div>
      </div>
    </div>
  )
}

export default OnboardingAgent
