import {ScreenProp} from 'src/app/models'

export type TLogKeys = 'LOG_LIST_AGEN' | 'LOG_LIST_ADMIN'

export const LogScreen: Record<TLogKeys, ScreenProp> = {
  LOG_LIST_AGEN: {
    PATH: '/log-activity/list/agent',
    TITLE: 'Aktivitas Log',
    TITLE_ALT: 'Aktivitas Log',
  },
  LOG_LIST_ADMIN: {
    PATH: '/log-activity/list/admin',
    TITLE: 'Aktivitas Log',
    TITLE_ALT: 'Aktivitas Log',
  },
}
