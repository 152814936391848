import axios from 'axios'
import dayjs from 'dayjs'
import id from 'dayjs/locale/id'
import ReactDOM from 'react-dom/client'
import {Provider} from 'react-redux'
import 'react-toastify/dist/ReactToastify.css'
import {PersistGate} from 'redux-persist/integration/react'
import App from './app/App'
import setupAxios from './app/plugins/axios/SetupAxios'
import './app/plugins/i18n/i18n'
import store, {persistor} from './app/store/Store'
import {loadEnv} from './app/utils/env-utils'
import './index.css'

dayjs.locale(id)

loadEnv().finally(() => {
  const {PUBLIC_URL} = process.env
  setupAxios(axios, store)

  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

  root.render(
    <Provider store={store}>
      <PersistGate persistor={persistor()} loading={<div>Loading...</div>}>
        <App basename={PUBLIC_URL} />
      </PersistGate>
    </Provider>
  )
})
