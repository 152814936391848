import {ScreenProp} from 'src/app/models'

type TKeys = 'SETTING' | 'SETTING_UMUM' | 'SETTING_PERUSAHAAN' | 'SETTING_PAKET'

const SettingScreens: Record<TKeys, ScreenProp> = {
  SETTING: {
    PATH: '/setting',
    TITLE: 'Setting',
    TITLE_ALT: 'Setting',
  },
  SETTING_UMUM: {
    PATH: '/setting/general',
    TITLE: 'Umum',
    TITLE_ALT: 'Umum',
  },
  SETTING_PERUSAHAAN: {
    PATH: '/setting/my-company',
    TITLE: 'Info Perusahaan',
    TITLE_ALT: 'Info Perusahaan',
  },
  SETTING_PAKET: {
    PATH: '/setting/services',
    TITLE: 'Paket & Layanan',
    TITLE_ALT: 'Paket & Layanan',
  },
}

export default SettingScreens
