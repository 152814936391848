import {FormVariant} from './FormBuilder.types'

export const getFormLabelStyle = (variant: FormVariant) => {
  switch (variant) {
    case 'FULL':
      return `col-span-12 mb-2`
    case 'ROW':
      return `col-span-12 md:col-span-3 mb-2 md:mb-0`
    default:
      return `col-span-12 md:col-span-3 mb-2 md:mb-0`
  }
}

export const getFormListStyle = (variant: FormVariant) => {
  switch (variant) {
    case 'FULL':
      return `col-span-12`
    case 'ROW':
      return `col-span-12 md:col-span-9`
    default:
      return `col-span-12 md:col-span-9`
  }
}
