import React from 'react'
import {useDispatch} from 'react-redux'
import {useLocation} from 'react-router'
import {Link} from 'react-router-dom'
import {useMasterLayout} from 'src/app/context/MasterContext'
import FilterRedux from 'src/app/store/Filter/FilterRedux'
import {cn} from 'src/app/utils/cn-utils'
import {checkIsActive} from 'src/app/utils/router-helpers-utils'
import {KTSVG} from '../../Libs/Icon/KTSVG'
import BulletItem from './BulletItem'

type Props = {
  children?: React.ReactNode
  to: string
  title: string
  icon?: string
  hasBullet?: boolean
  activePath?: string
}

const AsideMenuItem: React.FC<Props> = ({children, to, title, icon, hasBullet, activePath}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, activePath ?? to)
  const {minimize} = useMasterLayout()
  const dispatch = useDispatch()

  return (
    <div className='relative block p-0 text-fs-9'>
      <Link
        className={cn(
          'p-2 m-2 cursor-pointer flex flex-nowrap items-center rounded-lg transition duration-100 h-10 truncate',
          {'bg-primary-500/50 text-white': isActive},
          {'text-white': !isActive}
        )}
        to={to}
        onClick={() => dispatch(FilterRedux.actions.setRemoveFilter())}
      >
        {hasBullet && <BulletItem isActive={isActive} />}

        {icon && (
          <span className='flex items-center w-8 mr-2 shrink-0'>
            <KTSVG path={icon} className='w-6 h-6' />
          </span>
        )}

        <span
          className={cn('flex items-center flex-1 transition duration-300', {hidden: minimize})}
        >
          {title}
        </span>
      </Link>
      {children}
    </div>
  )
}

export default AsideMenuItem
