/* eslint-disable react-hooks/exhaustive-deps */
import React, {createContext, useCallback, useContext, useMemo} from 'react'
import {useSelectorContext} from '../hooks/selector.hook'

export interface PageToasts {
  text: string
  scheme: 'info' | 'danger' | 'success' | 'primary' | 'warning'
  timestamp?: number
  shown?: boolean
  fixed?: boolean
}

export interface HeaderToastContextModel {
  hookPageToasts: <R>(listener: (data: PageToasts[] | undefined) => R | undefined) => R | undefined
  addPageToasts: (toast: PageToasts) => void
  removePageToasts: (toast: PageToasts) => void
}

const HeaderToastContext = createContext<HeaderToastContextModel>({
  hookPageToasts: (() => null) as any,
  addPageToasts: (toast: PageToasts) => {},
  removePageToasts: (toast: PageToasts) => {},
})

export const HeaderToastProvider: React.FC<{children?: React.ReactNode}> = ({children}) => {
  const [, hookPageToasts, setPageToasts] = useSelectorContext<Array<PageToasts>>([])
  const addPageToasts = useCallback((toast: PageToasts) => {
    const timestamp = Date.now()
    const entry = {
      ...toast,
      timestamp,
      shown: false,
    } as PageToasts
    setPageToasts((old) => [...old, entry])
    setTimeout(() => {
      entry.shown = true
      setPageToasts((old) => [...old])
    }, 50)
  }, [])
  const removePageToasts = useCallback((toast: PageToasts) => {
    toast.shown = false
    setPageToasts((old) => [...old])
    setTimeout(() => setPageToasts((old) => old.filter((data) => data !== toast)), 500)
  }, [])

  const value: HeaderToastContextModel = useMemo(
    () => ({
      hookPageToasts,
      addPageToasts,
      removePageToasts,
    }),
    [hookPageToasts, addPageToasts, removePageToasts]
  )
  return <HeaderToastContext.Provider value={value}>{children}</HeaderToastContext.Provider>
}

export function useHeaderToast() {
  return useContext(HeaderToastContext)
}
