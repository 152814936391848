import axios from 'axios'
import {BaseResponse, Pagination, Response} from '../../models'
import {
  IAdminDivision,
  IAdminDivisionQuery,
  IAdminDivisionRequest,
  IDivisionPermission,
} from '../../models/User/AdminDivision.model'

export const DIVISIONS = '/api/v1/admins/division'
export const PERMISSIONS = '/api/v1/admins/permissions'

// react-query key
export const LIST_DIVISION_KEY = 'list-admin-division'
export const DETAIL_DIVISION_KEY = 'detail-admin-division'
export const DETAIL_PERMISSION_KEY = 'detail-admin-permission'

export const getListDivision = (params?: IAdminDivisionQuery) => {
  return axios.get<BaseResponse<Pagination<IAdminDivision[]>>>(DIVISIONS, {params})
}

export const getDetailDivision = (id?: string) => {
  return axios.get<BaseResponse<Response<IAdminDivision>>>(`${DIVISIONS}/${id}`)
}

export const saveDivision = (data?: IAdminDivisionRequest, id?: string) => {
  if (id) return axios.put(`${DIVISIONS}/${id}`, data)
  return axios.post(DIVISIONS, data)
}

export const getDefaultPermission = (level?: 'L1' | 'L2' | 'L3') => {
  return axios.get<BaseResponse<Response<IDivisionPermission>>>(`${PERMISSIONS}-${level}`)
}
