import React from 'react'
import {cn} from 'src/app/utils/cn-utils'

const BulletItem: React.FC<{isActive: boolean}> = ({isActive}) => {
  return (
    <span className='flex items-center justify-center w-8 mr-2 shrink-0'>
      <span
        data-testid='bullet'
        className={cn(
          'w-1 h-1 rounded-full',
          'hover:text-white/70',
          {'bg-white/90': isActive},
          {'bg-white': !isActive}
        )}
      ></span>
    </span>
  )
}

export default BulletItem
