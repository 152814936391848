import {FC} from 'react'
import {useMasterLayout} from 'src/app/context/MasterContext'
import {cn} from 'src/app/utils/cn-utils'
import {Card} from '../../Libs'
import {KTSVG} from '../../Libs/Icon/KTSVG'

const AsideToggler: FC = () => {
  const {minimize, setMinimize} = useMasterLayout()

  return (
    <Card.Root
      data-testid='toggle-button'
      onClick={() => {
        setMinimize(!minimize)
      }}
      className={cn(
        'cursor-pointer z-1 absolute w-8 h-8 rounded-full top-50 right-0 translate-x-[50%] items-center justify-center border border-neutral-200',
        'lg:flex',
        {hidden: minimize},
        {flex: !minimize}
      )}
    >
      <KTSVG
        path={'/media/icons/IconArrowLeftBar.svg'}
        className={cn('h-24px w-24px transition-all duration-300', {
          'rotate-180': minimize,
        })}
      />
    </Card.Root>
  )
}

export default AsideToggler
