import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export interface IDivisionState {
  level?: string
  division?: string
}

namespace DivisionRedux {
  export const actionTypes = {
    SetLevelDivision: '[SetLevelDivision] Action',
    SetNameDivision: '[SetNameDivision] Action',
    SetClearDivision: '[SetClearDivision] Action',
  }

  export const initialDivisionState: IDivisionState = {
    level: '',
    division: '',
  }

  export const reducer = () =>
    persistReducer<IDivisionState, ActionWithPayload<IDivisionState>>(
      {
        storage,
        key: 'DIVISION-FORM',
        whitelist: ['level', 'division'],
      },
      (state: IDivisionState = initialDivisionState, action = {type: ''}) => {
        switch (action.type) {
          case actionTypes.SetLevelDivision: {
            const level = action.payload?.level

            return {...state, level}
          }

          case actionTypes.SetNameDivision: {
            const division = action.payload?.division

            return {...state, division}
          }

          case actionTypes.SetClearDivision: {
            return initialDivisionState
          }

          default:
            return state
        }
      }
    )

  export const actions = {
    setLevelDivision: (data: IDivisionState) => ({
      type: actionTypes.SetLevelDivision,
      payload: data,
    }),
    setNameDivision: (data: IDivisionState) => ({
      type: actionTypes.SetNameDivision,
      payload: data,
    }),
    setClearDivision: () => ({
      type: actionTypes.SetClearDivision,
    }),
  }
}

export default DivisionRedux
