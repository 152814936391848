import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {FC, Suspense} from 'react'
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3'
import {BrowserRouter} from 'react-router-dom'
import {LayoutProvider} from './context/LayoutContext'
import {LayoutSplashScreen} from './context/SplashScreenContext'
import {Routes} from './routers/Routes'
import AuthInit from './store/Auth/AuthInit'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
    mutations: {
      retry: false,
      retryDelay: 0,
      networkMode: 'always',
    },
  },
})

type Props = {
  basename?: string
}

const App: FC<Props> = ({basename}) => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITEKEY as string}
          >
            <LayoutProvider>
              <AuthInit>
                <Routes />
              </AuthInit>
            </LayoutProvider>
          </GoogleReCaptchaProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export default App
