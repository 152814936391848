import {FC, useCallback, useEffect, useMemo, useState} from 'react'
import Select, {ClassNamesConfig, SelectComponentsConfig} from 'react-select'
import {AsyncPaginate} from 'react-select-async-paginate'
import {cn} from 'src/app/utils/cn-utils'
import {CustomReactSelect} from '../../Custom/CustomReactSelect'
import {FormError} from '../FormError'
import {FormSelectProps, SelectOption} from './Select.types'

export const FormSelect: FC<FormSelectProps> = ({
  options,
  placeholder,
  error,
  touched,
  defaultValue,
  className,
  changedValue,
  disabled,
  selectedValue,
  asyncSelect,
  loadOptions,
  cacheUniqs,
  isOptionDisabled,
  allowSelectAll = false,
  allowSelectAllPlaceholder = 'All Items',
}) => {
  const [val, setVal] = useState<SelectOption | SelectOption[] | ''>('')

  const selectAllOption = useMemo(
    () => ({
      value: '<SELECT_ALL>',
      label: allowSelectAllPlaceholder,
    }),
    [allowSelectAllPlaceholder]
  )

  const getAsyncOptions = useCallback(
    async (search: string, loadedOptions: any) => {
      const previousOptions: Array<any> = allowSelectAll ? loadedOptions.slice(1) : loadedOptions
      const payload = await loadOptions(search, previousOptions)

      const newSelectAll = {
        options: allowSelectAll && loadedOptions.length === 0 ? [selectAllOption] : [],
        hasMore: payload.hasMore,
      }

      newSelectAll.options.push(...payload.options)

      setVal((prev) => prev)

      return newSelectAll
    },
    [allowSelectAll, selectAllOption, loadOptions]
  )

  const onChange = (v: SelectOption) => {
    if (changedValue) {
      changedValue(v)
      setVal(v)
    }
  }

  useEffect(() => {
    if (!asyncSelect) {
      if (selectedValue === '' || selectedValue === undefined) {
        setVal('')
      } else {
        const payload = options?.find((pay) => pay.value === selectedValue)
        setVal({value: selectedValue, label: payload?.label})
      }
    }
  }, [selectedValue, options, asyncSelect])

  // watch this new
  useEffect(() => {
    if (asyncSelect) {
      if (selectedValue === '') {
        setVal('')
      }
    }
  }, [selectedValue, asyncSelect])

  useEffect(() => {
    if (asyncSelect && defaultValue) {
      setVal({value: defaultValue?.value, label: defaultValue?.label})
    }
  }, [defaultValue, asyncSelect])

  const hardClassName: ClassNamesConfig<any, false, any> = {
    placeholder: () => 'text-neutral-400 font-normal',
    control: () =>
      cn(
        'text-fs-9 rounded-lg font-medium text-neutral-700 border-neutral-300 hover:border-neutral-300 bg-white',
        `${touched && error ? 'border-danger-500 hover:border-danger-500' : ''}`,
        `${disabled && 'bg-neutral-100'}`
      ),
    valueContainer: (state) => `min-h-0 px-4 ${state.isMulti ? 'py-0 h-11' : 'py-3'}`,
    singleValue: (state) => cn(disabled && 'text-neutral-700'),
    input: () => 'm-0 p-0 text-neutral-700 font-normal',
    dropdownIndicator: (state) =>
      `transition-transform duration-150 ${
        state.selectProps.menuIsOpen ? 'rotate-180' : 'rotate-0'
      }`,
    indicatorsContainer: () => 'max-h-[44px]',
    option: (state) =>
      cn(
        `text-fs-9 rounded px-2 py-3 cursor-pointer`,
        state.isFocused ? 'text-primary-500' : 'text-[#495057]',
        state.isDisabled ? 'text-neutral-300' : ''
      ),
    menuList: () => 'p-3 border-none max-h-[300px]',
    menu: () => 'shadow-none drop-shadow-[0_0_50px_rgba(33,37,41,0.13)]',
    menuPortal: () => 'z-[999999]',
  }

  return (
    <div data-testid='formselect-test' className={className}>
      {asyncSelect ? (
        <AsyncPaginate
          data-testid='formselect-test-value'
          defaultOptions
          placeholder={placeholder}
          onChange={onChange}
          loadOptions={getAsyncOptions}
          debounceTimeout={500}
          components={CustomReactSelect as SelectComponentsConfig<any, false, any>}
          value={val}
          defaultValue={defaultValue}
          isDisabled={disabled}
          styles={{
            option: (base, state) => ({
              ...base,
              backgroundColor: state.isFocused ? '#EFF6FF' : '',
            }),
          }}
          cacheUniqs={cacheUniqs}
          classNames={hardClassName}
          isSearchable
          menuPortalTarget={document.querySelector('body')}
          isOptionDisabled={isOptionDisabled}
          menuPlacement='auto'
        />
      ) : (
        <Select
          data-testid='formselect-test-value'
          components={CustomReactSelect as SelectComponentsConfig<any, false, any>}
          options={options}
          onChange={onChange}
          placeholder={placeholder}
          defaultValue={defaultValue}
          value={val}
          isDisabled={disabled}
          styles={{
            option: (base, state) => ({
              ...base,
              backgroundColor: state.isFocused ? '#EFF6FF' : '',
            }),
          }}
          classNames={hardClassName}
          menuPortalTarget={document.querySelector('body')}
          isOptionDisabled={isOptionDisabled}
          menuPlacement='auto'
        />
      )}

      {touched && error && <FormError>{error}</FormError>}
    </div>
  )
}
