import {FC} from 'react'
import {Bounce, ToastOptions} from 'react-toastify'
import GIcon, {GIconProps} from 'src/app/components/Libs/Icon/GIcon'
import {cn} from 'src/app/utils/cn-utils'

export const CustomToastStyling: ToastOptions = {
  position: 'bottom-left',
  autoClose: 5000,
  hideProgressBar: true,
  closeButton: false,
  transition: Bounce,
  className: 'w-[520px] p-0 shadow-none',
  bodyClassName: 'p-0 bg-transparent',
}

interface ICustomToast {
  message: string
  variant?: 'SUCCESS' | 'DANGER' | 'WARNING'
  closeToast?: any
}

const VARIANT_STYLES: Record<
  NonNullable<ICustomToast['variant']>,
  {textColor: string; themeColor: string; icon: GIconProps}
> = {
  DANGER: {
    textColor: 'text-danger-500',
    themeColor: 'bg-danger-50 border-danger-300',
    icon: 'IconInfoOutline',
  },
  SUCCESS: {
    textColor: 'text-success-500',
    themeColor: 'bg-success-50 border-success-300',
    icon: 'IconCheckCircle',
  },
  WARNING: {
    textColor: 'text-warning-500',
    themeColor: 'bg-warning-50 border-warning-300',
    icon: 'IconInfoOutline',
  },
}

const CustomToast: FC<ICustomToast> = ({closeToast, message, variant = 'SUCCESS'}) => {
  const {textColor, themeColor, icon} = VARIANT_STYLES[variant]

  return (
    <div
      className={cn(
        'p-4 w-[520px] h-[64px] rounded-lg flex items-center justify-between border',
        themeColor
      )}
    >
      <div className='flex items-center gap-3'>
        <div className={textColor}>
          <GIcon icon={icon} className='w-6 h-6' />
        </div>
        <div className='font-semibold text-neutral-600 text-fs-9'>{message}</div>
      </div>
      <div className={cn(textColor, 'cursor-pointer')} onClick={closeToast}>
        <GIcon icon='IconClose' className='w-6 h-6' />
      </div>
    </div>
  )
}

export default CustomToast
