import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export interface IGlobalParamState {
  globalParams: any
}

namespace GlobalParamRedux {
  export const actionTypes = {
    SetGlobalParams: '[SetGlobalParams] Action',
    SetRemoveGlobalParams: '[SetRemoveGlobalParams] Action',
  }

  export const initialGlobalState: IGlobalParamState = {
    globalParams: undefined,
  }

  export const reducer = () =>
    persistReducer<IGlobalParamState, ActionWithPayload<IGlobalParamState>>(
      {
        storage,
        key: 'GLOBAL-PARAM',
        whitelist: ['globalParams'],
      },
      (state: IGlobalParamState = initialGlobalState, action = {type: ''}) => {
        switch (action.type) {
          case actionTypes.SetGlobalParams: {
            const globalParams = action?.payload
            return {globalParams}
          }

          case actionTypes.SetRemoveGlobalParams: {
            return initialGlobalState
          }

          default:
            return state
        }
      }
    )

  export const actions = {
    setGlobalParams: (data: IGlobalParamState[]) => ({
      type: actionTypes.SetGlobalParams,
      payload: data,
    }),
    setRemoveGlobalParams: (data: IGlobalParamState[]) => ({
      type: actionTypes.SetRemoveGlobalParams,
    }),
  }
}

export default GlobalParamRedux
